import styled from 'styled-components';

import { getPaddingTop, media } from '@belong/themes';

import { VideoContainer } from '../../media/VideoContainer';

export const MediaContainer = styled.div`
  ${media('xl')`
      min-height: 352px;
  `}
`;

export const ResponsiveWidescreen = styled.div`
  /**
   * any parents using 'display: flex' will give this element zero width. 
   * This will keep it running smoothly
   */
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ${(): string => getPaddingTop('16:9')};
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const StyledVideoContainer = styled(VideoContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
