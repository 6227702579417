import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
`;
