import * as React from 'react';
import { Wrapper } from './IframeContainer.styles';

interface IProps {
  title?: string;
  iframeUrl: string;
  'data-testid'?: string;
}

const IframeContainer: React.FC<IProps> = ({ iframeUrl, title, 'data-testid': testId }: IProps) => {
  return (
    <Wrapper data-testid={testId || `iframe-container`}>
      <iframe title={title} width="100%" height="500" src={iframeUrl} frameBorder="0" allow="geolocation" />
    </Wrapper>
  );
};

IframeContainer.displayName = 'IframeContainer';
export default IframeContainer;
