import React from 'react';
import { HEADING_VARIANTS } from '@belong/types';
import { ContentFormatter, Heading, Section, TextMediaBlock } from '@belong/ui-components';
import { ISectionTextImageBlock } from './model';

const SectionTextImageBlock: React.FC<ISectionTextImageBlock> = ({
  id,
  identifier,
  sectionHeading,
  sectionStyles,
  textSide,
  ...otherProps
}) => {
  return (
    <Section data-contentid={id} data-identifier={identifier} data-testid="section-text-image-block" {...sectionStyles}>
      {sectionHeading && (
        <ContentFormatter alignment="center">
          <Heading as="h2" variant={HEADING_VARIANTS.large}>
            {sectionHeading}
          </Heading>
        </ContentFormatter>
      )}
      {<TextMediaBlock {...otherProps} textSide={textSide} />}
    </Section>
  );
};

export default SectionTextImageBlock;
