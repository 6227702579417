import * as React from 'react';
import { useEffect } from 'react';
import { IImage, ILink, IVideo } from '@belong/types';
import { logger } from '@belong/logging/logger';
import { Document } from '@contentful/rich-text-types';
import { BREAKPOINTS } from '@belong/themes';
import { VideoEmbed } from '../../media/VideoEmbed';
import ButtonGroup from '../../styles/ButtonGroup';
import ContentFormatter from '../../styles/ContentFormatter';
import { RichText, TEXT_NODES } from '../../styles/Typography/RichText';
import { PictureContainer } from '../../media/PictureContainer';
import IframeContainer from '../../layout/IframeContainer';
import { Column, Columns, Stack } from '../../ui';
import { Button } from '../../actions';

import { MediaContainer, ResponsiveWidescreen, StyledVideoContainer } from './TextMediaBlock.styles';

const TEST_ID_PROP_NAME = 'data-testid' as const;

export enum TEXT_SIDE {
  START = 'start',
  END = 'end'
}

export interface ITextMediaBlock {
  textSide: TEXT_SIDE;
  [TEST_ID_PROP_NAME]?: string;
  image?: IImage;
  video?: IVideo; // MP4 video
  videoId?: string; // Youtube video
  iframeUrl?: string;
  iframeTitle?: string;
  link?: ILink;
  secondaryLink?: ILink;
  content: Document;
}

export enum TEST_IDS {
  ROOT = 'text-media-block',
  /**
   * This value is wrong; need to correct it in the regression suite
   */
  CONTENT_CONTAINER = 'headingContainer',
  IMAGE_CONTAINER = 'image-container',
  VIDEO_CONTAINER = 'video-container',
  IFRAME_CONTAINER = 'iframe-container'
}

const TextMediaBlock: React.FC<ITextMediaBlock> = ({
  textSide,
  image,
  video,
  videoId,
  iframeUrl,
  iframeTitle,
  link,
  secondaryLink,
  content,
  'data-testid': dataTestId
}: ITextMediaBlock) => {
  const mediaNumber = [Boolean(image), Boolean(video), Boolean(videoId), Boolean(iframeUrl)].filter(a => a).length;

  useEffect(() => {
    if (mediaNumber >= 2) {
      logger.error(
        'Invalid prop combination for TextMediaBlock: you must use either `image` or `videoId` or `iframeUrl`, but not multiple.'
      );
    }
  }, [mediaNumber]);

  /**
   * Don't render anything if the props are invalid
   */
  if (mediaNumber >= 2) {
    return null;
  }

  return (
    <Columns
      alignX="center"
      alignY="stretch"
      collapseBelow="lg"
      data-testid={dataTestId ?? TEST_IDS.ROOT}
      reverse={textSide === TEXT_SIDE.START ? 'whenCollapsed' : 'always'}
      space={{ xs: 'large', lg: 'xlarge', xl: 'xxlarge' }}
    >
      <Column width="1/2" alignY="center" data-testid={TEST_IDS.CONTENT_CONTAINER}>
        <Stack space="medium">
          <ContentFormatter>
            <RichText h2={TEXT_NODES.headingLarge} h3={TEXT_NODES.headingMedium} html={content} />
          </ContentFormatter>
          <ButtonGroup hasControl={{ xs: ['column', 'left', false], md: ['row', 'left', true] }}>
            {link && (
              <Button width={{ xs: 'full', md: 'default' }} {...link}>
                {link.label}
              </Button>
            )}
            {secondaryLink && (
              <Button width={{ xs: 'full', md: 'default' }} {...secondaryLink}>
                {secondaryLink.label}
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Column>
      <Column width="1/2" alignY={videoId ? 'top' : 'center'}>
        <MediaContainer>
          {image && (
            <ResponsiveWidescreen data-testid={TEST_IDS.IMAGE_CONTAINER}>
              <PictureContainer
                src={{
                  xs: [image.src, { w: BREAKPOINTS.md }],
                  md: [image.src, { w: BREAKPOINTS.lg }]
                }}
                alt={image.alt}
                contentType={image.contentType}
              />
            </ResponsiveWidescreen>
          )}
          {video && (
            <ResponsiveWidescreen data-testid={TEST_IDS.VIDEO_CONTAINER}>
              <StyledVideoContainer {...video} />
            </ResponsiveWidescreen>
          )}
          {videoId && (
            <div data-testid={TEST_IDS.VIDEO_CONTAINER}>
              <VideoEmbed videoId={videoId} />
            </div>
          )}
          {!!iframeUrl && (
            <IframeContainer data-testid={TEST_IDS.IFRAME_CONTAINER} iframeUrl={iframeUrl} title={iframeTitle} />
          )}
        </MediaContainer>
      </Column>
    </Columns>
  );
};

TextMediaBlock.displayName = 'TextMediaBlock';

export default TextMediaBlock;
